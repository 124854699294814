<template>
	<Loader :value="promises.fetch" theme="default" class="gallery-page">
    <Blocks :blocks="config" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CustomComponents from '@/components/Custom'
import { Blocks } from '@kvass/pagebuilder'
import Config from '@/config/gallery'
import BrowserApiMixin from '@/mixins/browser-api'

export default {
	mixins: [BrowserApiMixin('gallery')],
	computed: {
		...mapState('Project', ['item', 'promises']),
		config(){
			return Config.call(this)
		},
		customComponents() {
			return { ...CustomComponents }
		}
	},
	methods: {
		...mapActions('Project', ['fetch'])
	},
	mounted() {
		this.fetch()
	},
	components: {
		Blocks
	}
}
</script>
