export default function () {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'gallery',
      },
      blocks: {
        default: [
          {
            component: 'Masonry',
            data: {
              content: get('Project.item.media.gallery'),
            },
          },
        ],
      },
    },
  ]
}
